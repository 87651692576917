<template>
  <div class="m-0 px-3 py-4">
    <back-button/>
    <div class="mb-3">
      <h1 class="m-0">Submit New Message</h1>
    </div>
    <div v-loading="loader">
      <validation-observer ref="addVideoForm" v-slot="{handleSubmit}">
        <form class="needs-validation" @submit.prevent="handleSubmit(storeSupportMessage)">
          <div class="col-lg-6 cardStyle py-4 px-4">
            <span v-html="formHeadingText"/>
            <div class="mt-4">
              <label class="font-size-16px font-weight-bold">Write Message</label>
              <textarea id="textArea"
                        v-model="request.supportMessage.message"
                        class="w-100 text-area-input"
                        placeholder=""
                        required>
          </textarea>
            </div>
          </div>
          <div class="mt-4">
            <base-button native-type="submit" type="submit" class="btn-secondary-action">
              Submit
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "StoreSupportMessage",
  components: {BackButton},
  data() {
    return {
      loader: false,
      supportMessageType: {
        'baritastic-support': 'baritastic_support',
        'support-groups': 'support_group',
        'vitamin-supplements': 'vitamin_supplement',
      },
      request: {
        supportMessage: {...this.$store.getters['SupportEmailAndMessageModule/getSupportMessage']}
      }
    }
  },
  computed: {
    /**
     * Get Support Type from params
     * @returns {string}
     */
    supportType() {
      return this.$route.params.type
    },

    /**
     * Form Heading Text
     * @returns {string}
     */
    formHeadingText() {
      if (this.supportType == 'baritastic-support') {
        return 'If you have questions about the Baritastic app or dashboard, need training, need to give a new ' +
          'employee access, need new content sections added or updated, please fill out the form below.'
      } else if (this.supportType == 'support-groups') {
        return 'Our expert clinical team has developed over 20 unique support group presentations to help address ' +
          'patient concerns from staying adequately hydrated to high-protein recipe ideas.<br><br>We have ' +
          'representatives trained on the support group presentations that can host your in-person or virtual support ' +
          'groups as well. There is no charge for this service.<br><br>Please fill out the form below if you’d like ' +
          'help with your support groups.'
      } else if (this.supportType == 'vitamin-supplements') {
        return 'We are happy to provide you with Bariatric Advantage vitamin samples that you can share with your ' +
          'patients.<br><br>Please fill out the form below. Include the quantity of samples you’d like and your ' +
          'clinic’s mailing address.'
      } else {
        return ''
      }
    },
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Store Support Message
     */
    storeSupportMessage() {
      let vm = this
      vm.loader = true
      vm.request.supportMessage.type = vm.supportMessageType[vm.supportType]
      vm.$store.dispatch('SupportEmailAndMessageModule/_storeSupportMessage', {formData: this.request.supportMessage})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Support Message Submitted Successfully!'
          })
          vm.$router.push({name: 'admin.dashboard'}).catch(() => {})
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Store Support Message',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>

.text-area-input {
  width: 100%;
  height: calc(1.5em + 1.25rem + 50px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

</style>
